import "./App.css";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import Main from "./Pages/Main";
import SuccessPage from "./Pages/successPage";
import FailPage from "./Pages/failPage";
import SubOperatorV2 from "./Pages/sub-operatorV2";
import SuccessPageMonthlyPayment from "./Pages/SubOperatorMonthlyPaymentSuccess";
import SuccessPageSubOperator from "./Pages/successPageSubOperator";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/sub-operator" element={<SubOperatorV2 />} />
        <Route path="/success-page" element={<SuccessPage />} />
        <Route path="/fail-page" element={<FailPage />} />
        <Route
          path="/monthly-subscription"
          element={<SuccessPageMonthlyPayment />}
        />
        <Route
          path="/success-page-sub-operator"
          element={<SuccessPageSubOperator />}
        />
      </Routes>
    </Router>
  );
}

export default App;
