import React, { useEffect, useState, useRef } from "react";
import { Card, Button, Result } from "antd";

import DoryLogo from "../assets/logo.jpg";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import renderInput from "../component/inputForm";
import phil from "../external_node_modules/philippine-location-json-for-geer";
import SubOperatorForm from "./SubOperatorForm";
import OperatorForm from "./OperatorForm";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Axios from "../helpers/axios";
import { LoadingOutlined } from "@ant-design/icons";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const SuccessPageSubOperator = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [paymentVerifier, setPaymentVerifier] = useState(false);
  const [loading, setLoading] = useState(false);
  // Get the reference_number from the query string
  const queryParams = new URLSearchParams(location.search);
  const query = useQuery();

  const referenceNumber = queryParams.get("ref");
  const paymentRef = query.get("ref");
  const hasUpdatedRef = useRef(false);
  console.log("Erf", referenceNumber);
  const paymentChecker = async () => {
    try {
      setLoading(true);
      const response = await Axios().post(
        `/api/sub-operator/account-creation`,
        {
          reference_number: referenceNumber,
        }
      );

      console.log(response);
      setLoading(false);

      if (response.data.message !== undefined) {
        setPaymentVerifier(true);
      }
    } catch (error) {
      setLoading(false);
      console.log("Payment Checker : >>> ", error);
    }
  };

  useEffect(() => {
    if (paymentRef && !hasUpdatedRef.current) {
      hasUpdatedRef.current = true;
      paymentChecker();
    }
  }, [paymentRef]);

  return (
    <div
      style={{
        backgroundColor: "#00BF63",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          minHeight: "100vh",
          padding: "0 10px",
          boxSizing: "border-box",
          backgroundColor: "#00BF63",
        }}
      >
        <Card
          style={{
            width: "100%",
            maxWidth: 700,
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingOutlined style={{ fontSize: 45, color: "#1E90FF" }} />
              <p style={{ fontSize: 20 }}>
                PLease wait while the system is verifying your payment.
              </p>
            </div>
          ) : (
            <Result
              status="success"
              title={
                <div>
                  <>
                    <p style={{ fontSize: 25 }}>
                      Payment successfully verified
                    </p>
                    <p style={{ fontSize: 20 }}>
                      Referrence number : {referenceNumber}
                    </p>
                  </>
                </div>
              }
              extra={[
                <Button
                  onClick={() => navigate("/")}
                  style={{ width: 180 }}
                  type="primary"
                  key="console"
                >
                  Go Back
                </Button>,
              ]}
            />
          )}
        </Card>
      </div>
    </div>
  );
};

export default SuccessPageSubOperator;
