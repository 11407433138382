import React from "react";
import { Image, Card } from "antd";

import DoryLogo from "../assets/logo.jpg";
import SubOperatorForm from "./SubOperatorForm";

const SubOperatorV2 = () => {
  return (
    <div
      style={{
        backgroundColor: "#00BF63",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          minHeight: "100vh",
          padding: "0 10px",
          boxSizing: "border-box",
          backgroundColor: "#00BF63",
        }}
      >
        <Card
          style={{
            width: "100%",
            maxWidth: 700,
            marginTop: "20px",
            marginBottom: "20px",
            position: "relative",
            paddingBottom: "40px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: -10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "2px",
              }}
            >
              <Image
                preview={false}
                src={DoryLogo}
                style={{ width: 90, height: 90 }}
              />
              <p style={{ fontSize: 30, fontWeight: "bold" }}>
                Sign Up As Sub Operator
              </p>
            </div>
            <SubOperatorForm role={"sub_operator"} />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default SubOperatorV2;
