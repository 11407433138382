import React, { useEffect, useState, useRef } from "react";
import { Card, Button, Result } from "antd";

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Axios from "../helpers/axios";
import { LoadingOutlined } from "@ant-design/icons";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const SuccessPageMonthlyPayment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const query = useQuery();

  const referenceNumber = queryParams.get("ref");
  const paymentRef = query.get("ref");
  const hasUpdatedRef = useRef(false);

  const paymentChecker = async () => {
    try {
      setLoading(true);

      const { data } = await Axios().post(
        "/api/sub-operator/subscription-payment-success",
        {
          reference_number: referenceNumber,
        }
      );

      setPaymentSuccess(true);
      setLoading(false);
    } catch (error) {
      navigate("/fail-page");
      setLoading(false);
      console.log("Payment Checker : >>> ", error);
    }
  };

  useEffect(() => {
    if (paymentRef && !hasUpdatedRef.current) {
      hasUpdatedRef.current = true;
      paymentChecker();
    }
  }, [paymentRef]);

  return (
    <div
      style={{
        backgroundColor: "#00BF63",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          minHeight: "100vh",
          padding: "0 10px",
          boxSizing: "border-box",
          backgroundColor: "#00BF63",
        }}
      >
        <Card
          style={{
            width: "100%",
            maxWidth: 700,
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingOutlined style={{ fontSize: 45, color: "#1E90FF" }} />
              <p style={{ fontSize: 20 }}>
                PLease wait while the system is verifying your payment.
              </p>
            </div>
          ) : paymentSuccess ? (
            <Result
              status="success"
              title={
                <div>
                  <>
                    <p style={{ fontSize: 25 }}>
                      Payment successfully verified
                    </p>
                    <p style={{ fontSize: 20 }}>
                      Referrence number : {referenceNumber}
                    </p>
                  </>
                </div>
              }
            />
          ) : null}
        </Card>
      </div>
    </div>
  );
};

export default SuccessPageMonthlyPayment;
