import React, { useState } from "react";
import { Form, Row, Col, Button, Checkbox } from "antd";
// import { useLocation } from "react-router-dom";

//Hooks
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
// Components
import renderInput from "../component/inputForm";
import phil from "../external_node_modules/philippine-location-json-for-geer";
import subOpTermsAndCondition from "../assets/SUB-OPERATOR-CONTRACT.pdf";
import Axios from "../helpers/axios";

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }
const SubOperatorForm = ({ role = {} }) => {
  const schema = yup.object().shape({
    full_name: yup.string().required("Fullname is a required field"),

    province: yup.string().required("Province is required field"),
    city: yup.string().required("City is required field"),
    barangay: yup.string().required("Barangay is required"),

    password: yup.string().required("Password is required"),
    username: yup
      .string()
      .required("Username is required field")
      .matches(/^\S*$/, "Username cannot contain spaces"),
    email: yup
      .string()
      .required("Email is required field")
      .email("Email must be a valid email")
      .matches(/^\S*$/, "Email cannot contain spaces"),
    contact_number: yup
      .string()
      .required("Contact number is required field")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      )
      .min(11, "too short")
      .max(11, "too long"),
  });

  let defaultValues = {
    full_name: "",
    contact_number: "",
    province: "",
    city: "",
    barangay: "",
    username: "",
    password: "",
    email: "",
  };

  // const query = useQuery();
  // const branchId = query.get("branchId");

  const [cities, setCities] = useState([]);
  const [barangays, setBarangays] = useState([]);
  const [loading, setLoading] = useState(false);

  const [acceptedTermsAndCondition, setAcceptedTermsAndCondition] =
    useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });

  const onCheck = (e) => {
    setAcceptedTermsAndCondition(!acceptedTermsAndCondition);
  };

  const onSubmit = async (values) => {
    try {
      setLoading(true);

      const province = phil.provinces.filter(
        (province) => province.prov_code === values.province
      )[0];
      const city = phil.city_mun.filter(
        (city) => city.mun_code === values.city
      )[0].name;
      const barangay = phil.barangays.filter(
        (barangay) => barangay.brgy_code === values.barangay
      )[0].name;

      const createData = {
        full_name: values.full_name,
        contact_number: values.contact_number,
        username: values.username,
        password: values.password,
        province: province.name,
        city: city,
        barangay: barangay,
        type: role,
        prov_code: values.province,
        email: values.email,
      };

      const response = await Axios().post(`/api/sub-operator/v2`, createData);

      if (response.data.message !== undefined) {
        reset(defaultValues);
        window.location.href = response.data.paymentLink;
      }

      setLoading(false);
    } catch (error) {
      console.log(">>>> Something Went Wrong while we save your data");
      setLoading(false);
    }
  };

  const onChangeProvince = (prov_code) => {
    setValue("city", "");
    setValue("barangay", "");
    setCities(phil.getCityMunByProvince(prov_code));
    setBarangays([]);
  };

  const onChangeCity = (mun_code) => {
    setValue("barangay", "");
    setBarangays(phil.getBarangayByMun(mun_code));
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}></div>
      <p style={{ fontSize: 16, fontWeight: "bold" }}>Personal Information</p>
      <Form
        layout="vertical"
        initialValues={{
          requiredMarkValue: true,
        }}
        requiredMark={true}
        onFinish={handleSubmit(onSubmit)}
        encType="multipart/form-data"
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            {renderInput(
              {
                label: "Full name",
                name: "full_name",
                errors: errors,
                required: "true",
              },
              control
            )}
          </Col>
          <Col className="gutter-row" span={12}>
            {renderInput(
              {
                label: "Email",
                name: "email",
                errors: errors,
                required: "true",
              },
              control
            )}
          </Col>
          <Col className="gutter-row" span={12}>
            {renderInput(
              {
                label: "Contact number",
                name: "contact_number",
                errors: errors,
                required: "true",
              },
              control
            )}
          </Col>

          <Col className="gutter-row" span={24}>
            <div>
              <p style={{ fontSize: 16, fontWeight: "bold" }}>
                Your Address Details
              </p>
            </div>
            {renderInput(
              {
                label: "Province",
                name: "province",
                errors: errors,
                required: "true",
                type: "select",
                options: phil.provinces,
                valueKey: "prov_code",
                valueText: "name",
                onChangeOutside: onChangeProvince,
              },
              control
            )}
          </Col>
          <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "City",
                name: "city",
                errors: errors,
                required: "true",
                type: "select",
                options: cities,
                valueKey: "mun_code",
                valueText: "name",
                onChangeOutside: onChangeCity,
              },
              control
            )}
          </Col>
          <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "Barangay",
                name: "barangay",
                errors: errors,
                required: "true",
                type: "select",
                options: barangays,
                valueKey: "brgy_code",
                valueText: "name",
                onChangeOutside: () => {},
              },
              control
            )}
          </Col>
        </Row>
        <p style={{ fontSize: 16, marginLeft: 5, fontWeight: "bold" }}>
          Create username and password
        </p>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            {renderInput(
              {
                label: "Username",
                name: "username",
                errors: errors,
                required: "true",
              },
              control
            )}
          </Col>
          <Col className="gutter-row" span={12}>
            {renderInput(
              {
                label: "Password",
                name: "password",
                errors: errors,
                required: "true",
                type: "password",
              },
              control
            )}
          </Col>
        </Row>

        <br />

        <div>
          <Checkbox onChange={onCheck}>
            {"I have read and agree to the"}
            <a
              href={subOpTermsAndCondition}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              terms of service
            </a>
          </Checkbox>
        </div>

        <br />
        <div className="text-right mt-5">
          <Button
            loading={loading}
            disabled={!acceptedTermsAndCondition}
            style={{
              width: "100%",
              backgroundColor: !acceptedTermsAndCondition
                ? "#AEAEAE"
                : "#00BF63",
            }}
            htmlType="submit"
            type="primary"
          >
            {
              <p
                style={{
                  fontSize: 18,
                  color: !acceptedTermsAndCondition ? "gray" : "white",
                }}
              >
                Submit
              </p>
            }
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default SubOperatorForm;
