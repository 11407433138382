import React, { useEffect, useState } from "react";
import { Card, Button, Result } from "antd";

import DoryLogo from "../assets/logo.jpg";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import renderInput from "../component/inputForm";
import phil from "../external_node_modules/philippine-location-json-for-geer";
import SubOperatorForm from "./SubOperatorForm";
import OperatorForm from "./OperatorForm";
import { useNavigate } from "react-router-dom";
const FailPage = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        backgroundColor: "#00BF63",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          minHeight: "100vh",
          padding: "0 10px",
          boxSizing: "border-box",
          backgroundColor: "#00BF63",
        }}
      >
        <Card
          style={{
            width: "100%",
            maxWidth: 700,
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <Result
            status="error"
            title={
              <div>
                <p style={{ fontSize: 25 }}>Payment Fail</p>
              </div>
            }
          />
        </Card>
      </div>
    </div>
  );
};

export default FailPage;
