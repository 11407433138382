import React, { useEffect, useState } from "react";
import {
  Image,
  Card,
  Form,
  Row,
  Col,
  Input,
  Button,
  Flex,
  Radio,
  Select,
  Modal,
  notification,
  Result,
  Checkbox,
} from "antd";
import Axios from "../helpers/axios";
import { useLocation, useNavigate } from "react-router-dom";
import opTermsAndCondition from "../assets/DORY-DELIVERY-CONTRACT.pdf";

//Image
import DoryLogo from "../assets/logo.jpg";

// Hooks
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

// Component
import renderInput from "../component/inputForm";
import phil from "../external_node_modules/philippine-location-json-for-geer";

import {
  LoadingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const OperatorForm = ({ role = {} }) => {
  const navigate = useNavigate();

  const schema = yup.object().shape({
    full_name: yup.string().required("Fullname is a required field"),
    // code: yup.string().required("code is required field"),
    province: yup.string().required("Province is required field"),
    city: yup.string().required("City is required field"),
    barangay: yup.string().required("Barangay is required"),
    contact_number: yup
      .string()
      .required("Contact number is required field")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      )
      .min(11, "too short")
      .max(11, "too long"),
    email: yup
      .string()
      .required("Email is required field")
      .email("Email must be a valid email")
      .matches(/^\S*$/, "Email cannot contain spaces"),
    coupon_code: yup.string(),
  });

  let defaultValues = {
    full_name: "",
    contact_number: "",
    province: "",
    city: "",
    barangay: "",
    username: "",
    password: "",
    scoped_area: "",
    scoped_province: "",
    scopred_city: "",
    coupon_code: "",
  };
  const query = useQuery();
  const referralCode = query.get("ref");
  const [cities, setCities] = useState([]);
  const [barangays, setBarangays] = useState([]);
  const [selectedMunicipality, setSelectedMunicipality] = useState([]);
  const [scopeAreaCity, setScopeAreaCity] = useState([]);
  const [type, setType] = useState("Sub_operator");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [availableArea, setAvailableArea] = useState([]);
  const [areas, setIsAreas] = useState([]);
  const [tempData, setTempData] = useState({});
  const [createLoading, setCreateLoading] = useState(false);
  const [arreaValidation, setAreaValidation] = useState(false);
  const [acceptedTermsAndCondition, setAcceptedTermsAndCondition] =
    useState(false);
  const [isfreeAccount, setIsFreeAccount] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });

  const openNotification = (message) => {
    notification.success({
      message: message,
    });
  };

  const onCheck = (e) => {
    setAcceptedTermsAndCondition(!acceptedTermsAndCondition);
  };

  const availableAreaChecker = async (data) => {
    try {
      setLoading(true);
      const response = await Axios().post(`/api/area-checker`, {
        province: data.province,
        city: data.city,
        partner_group_areas: data.partner_group_areas,
      });
      setLoading(false);
      setAvailableArea(response.data.data);
      setIsAreas(response.data.selectedArea);
      setTempData(data);
    } catch (error) {
      setLoading(false);
      console.log("response : >> ", error);
    }
  };

  const createTempData = async () => {
    try {
      setCreateLoading(true);
      const response = await Axios().post(`/api/sub-operator`, {
        password: tempData.password,
        username: tempData.username,
        barangay: tempData.barangay,
        city: tempData.city,
        province: tempData.province,
        email: tempData.email,
        contact_number: tempData.contact_number,
        full_name: tempData.full_name,
        referral_code: tempData.referral_code,
        service_city: tempData.service_city,
        service_province: tempData.service_province,
        type: tempData.type,
        partner_group_areas: JSON.stringify(availableArea),
        prov_code: tempData.prov_code,
        isFree: isfreeAccount,
      });
      reset(defaultValues);
      setSelectedMunicipality([]);
      if (response.data.message !== undefined) {
        window.location.href = response.data.paymentLink;
        // navigate("/success-page");
      }
    } catch (error) {
      setCreateLoading(false);
      console.log("createTempData : >>> ", error);
    }
  };

  const onSubmit = async (values) => {
    try {
      if (values.coupon_code === "01100110") {
        setIsFreeAccount(true);
      }

      if (selectedMunicipality.length !== 0) {
        const province = phil.provinces.filter(
          (province) => province.prov_code === values.province
        )[0];
        const city = phil.city_mun.filter(
          (city) => city.mun_code === values.city
        )[0].name;
        const barangay = phil.barangays.filter(
          (barangay) => barangay.brgy_code === values.barangay
        )[0].name;

        // let filteredSelectedMunicipality = [];
        // selectedMunicipality
        //   .filter((d) => d !== "")
        //   .map((dd) => filteredSelectedMunicipality.push(dd));
        let serviceCity = [];
        const createData = {
          full_name: values.full_name,
          contact_number: values.contact_number,
          username: values.username,
          password: values.password,
          province: province.name,
          city: city,
          barangay: barangay,
          type: role,
          partner_group_areas: [selectedMunicipality],
          prov_code: values.province,
          service_province: null,
          service_city: null,
          referral_code: referralCode,
          email: values.email,
        };
        setAreaValidation(false);
        setIsModalOpen(true);
        availableAreaChecker(createData);
      } else {
        setAreaValidation(true);
      }
    } catch (error) {
      console.log("onSubmit", error);
    }
  };

  console.log(errors);

  const close = () => {
    reset(defaultValues);
  };
  const onChangeProvince = (prov_code) => {
    setValue("city", "");
    setValue("barangay", "");
    setCities(phil.getCityMunByProvince(prov_code));
    setBarangays([]);
  };

  const onChangeCity = (mun_code) => {
    setValue("barangay", "");
    setBarangays(phil.getBarangayByMun(mun_code));
  };

  return (
    <div>
      <div>
        <div style={{ display: "flex", justifyContent: "center" }}></div>
        <p style={{ fontSize: 16, fontWeight: "bold" }}>Personal Information</p>
        <Form
          layout="vertical"
          initialValues={{
            requiredMarkValue: true,
          }}
          requiredMark={true}
          onFinish={handleSubmit(onSubmit)}
          encType="multipart/form-data"
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row" span={12}>
              {renderInput(
                {
                  label: "Full name",
                  name: "full_name",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>
            <Col className="gutter-row" span={12}>
              {renderInput(
                {
                  label: "Email",
                  name: "email",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>
            <Col className="gutter-row" span={12}>
              {renderInput(
                {
                  label: "Contact number",
                  name: "contact_number",
                  errors: errors,
                  required: "true",
                },
                control
              )}
            </Col>

            <Col className="gutter-row" span={24}>
              <div>
                <p style={{ fontSize: 16, fontWeight: "bold" }}>
                  Your Address Details
                </p>
              </div>
              {renderInput(
                {
                  label: "Province",
                  name: "province",
                  errors: errors,
                  required: "true",
                  type: "select",
                  options: phil.provinces,
                  valueKey: "prov_code",
                  valueText: "name",
                  onChangeOutside: onChangeProvince,
                },
                control
              )}
            </Col>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "City",
                  name: "city",
                  errors: errors,
                  required: "true",
                  type: "select",
                  options: cities,
                  valueKey: "mun_code",
                  valueText: "name",
                  onChangeOutside: onChangeCity,
                },
                control
              )}
            </Col>
            <Col className="gutter-row" span={24}>
              {renderInput(
                {
                  label: "Barangay",
                  name: "barangay",
                  errors: errors,
                  required: "true",
                  type: "select",
                  options: barangays,
                  valueKey: "brgy_code",
                  valueText: "name",
                  onChangeOutside: () => {},
                },
                control
              )}
            </Col>

            <Col className="gutter-row" span={24}>
              <p style={{ fontSize: 16, marginLeft: 5, fontWeight: "bold" }}>
                Area You Want to Lock Up
              </p>
              <Select
                value={selectedMunicipality}
                name={`municipality`}
                allowClear
                style={{ width: "100%" }}
                placeholder={`Please select Municipality`}
                onChange={(value) => {
                  setSelectedMunicipality(value);
                }}
                options={cities.map((city) => ({
                  value: city.name,
                  label: city.name,
                }))}
              />
              {arreaValidation && (
                <p style={{ color: "red" }}>Scope area is required</p>
              )}
              {selectedMunicipality === undefined && (
                <p style={{ color: "red" }}>Scope area is required</p>
              )}
            </Col>

            <Col className="gutter-row" span={12} style={{ marginTop: 32 }}>
              {renderInput(
                {
                  label: "Coupon code for discount (Optional)",
                  name: "coupon_code",
                  errors: errors,
                  required: false,
                },
                control
              )}
              {arreaValidation && (
                <p style={{ color: "red" }}>Scope area is required</p>
              )}
              {selectedMunicipality === undefined && (
                <p style={{ color: "red" }}>Scope area is required</p>
              )}
            </Col>
          </Row>
          <br />

          <div>
            <Checkbox onChange={onCheck}>
              {"I have read and agree to the"}
              <a
                href={opTermsAndCondition}
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                terms of service
              </a>
            </Checkbox>
          </div>
          <br />
          <div className="text-right mt-5">
            <Button
              disabled={!acceptedTermsAndCondition}
              style={{
                width: "100%",
                backgroundColor: !acceptedTermsAndCondition
                  ? "#AEAEAE"
                  : "#00BF63",
              }}
              htmlType="submit"
              type="primary"
            >
              {
                <p
                  style={{
                    fontSize: 18,
                    color: !acceptedTermsAndCondition ? "gray" : "white",
                  }}
                >
                  Submit
                </p>
              }
            </Button>
          </div>
        </Form>
      </div>
      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(!isModalOpen)}
        width={660}
        footer={[
          <Button
            disabled={createLoading || loading}
            onClick={() => setIsModalOpen(!isModalOpen)}
          >
            Cancel
          </Button>,
          <Button
            disabled={createLoading || loading || availableArea.length === 0}
            loading={createLoading}
            key="submit"
            type="primary"
            onClick={createTempData}
          >
            Continue
          </Button>,
        ]}
      >
        {referralCode !== null ? (
          <>
            {loading ? (
              <div>
                <p style={{ textAlign: "center", fontSize: 18 }}>
                  Wait while the system is checking if the selected service area
                  are still available.{" "}
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <LoadingOutlined style={{ fontSize: 65, color: "#1E90FF" }} />{" "}
                  <p style={{ fontSize: 30 }}> {selectedMunicipality}</p>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                }}
              >
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    flexWrap: "wrap",
                    marginTop: -30,
                  }}
                >
                  {availableArea.map((d, index) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 10,
                        marginTop: 30,
                      }}
                    >
                      <p style={{ fontSize: 20 }}>
                        Selected service area is available.
                      </p>
                      <CheckCircleOutlined
                        style={{ fontSize: 65, color: "green" }}
                      />
                      <p style={{ fontSize: 30, marginBottom: -15 }}> {d}</p>
                    </div>
                  ))}
                  {areas
                    .filter((dd) => !availableArea.includes(dd))
                    .map((area) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: 10,
                          marginTop: 30,
                        }}
                      >
                        {/* <p style={{ fontSize: 20 }}>
                      Select service area is available
                    </p> */}
                        <p style={{ fontSize: 22 }}>
                          Selected service area are not available.
                        </p>
                        <CloseCircleOutlined
                          style={{ fontSize: 65, color: "tomato" }}
                        />
                        <p style={{ fontSize: 30, marginBottom: -15 }}>
                          {" "}
                          {area}
                        </p>
                      </div>
                    ))}

                  <br />
                  {availableArea.length !== 0 && (
                    <p
                      style={{
                        fontSize: 20,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      To proceed, please click "Continue"
                    </p>
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          <div>
            <Result status="error" title="Referral Code Is Invalid"></Result>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default OperatorForm;
